<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="banner">
        <img src="../../assets/images/work/life-project.jpg" alt="">
      </div>
      <div class="wrap wrap1">
        <div class="page">
          <div class="title">
            住宅产品
          </div>
          <div class="content-wrap">
            <div class="left">
              <img src="../../assets/images/work/house.jpg" alt="">
            </div>
            <div class="right">
              <div class="title">
                <span>以美好生活为蓝本</span>
                <span>演绎庭院人居美学</span>
              </div>
              <div class="desc">
                小院起源于中国传统的居住形式——合院，即以中国围合式院落形态为精神蓝本，并结合Townhouse的空间特色，古建新制而开发的低层绿色庭院式住宅产品。这种产品空间层次丰富灵动，既充分彰显居者的私享空间,又能实现人车分流，前庭院后生态车位设计，与园林景观相映成趣，人与自然和谐交融；生活全周期服务配套，提供独树一帜的社区生活体验；邻里之间既能保证居者空间又和谐通融，从建筑和规划的角度来看，可谓人居范本。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap wrap2">
        <div class="page">
          <div class="title">
            服务平台
          </div>
          <div class="content-wrap">
            <div class="item-wrap">
              <img src="../../assets/images/work/service.jpg" alt="">
              <div class="bottom-wrap">
                <div class="img-wrap">
                  <img src="../../assets/images/work/360.png" alt="">
                </div>
                <div class="title">优居服务配套 悦享360°便利</div>
                <div class="desc">
                  小院生活+是以小院生活品牌为基础，提供与消费者居住体验相关的全周期配套服务，力求于满足消费者与居住体验相关的一站式服务。同时，依托于小院绿色生态体系与科技创新力量，与小院合作商共同为用户提供更为智慧的商业服务模式。
                </div>
              </div>
            </div>
            <div class="item-wrap">
              <img src="../../assets/images/work/life.jpg" alt="">
              <div class="bottom-wrap">
                <div class="img-wrap">
                  <img src="../../assets/images/work/life.png" alt="">
                </div>
                <div class="title">打造第三空间 定义理想生活</div>
                <div class="desc">
                  在信息纵横、全民焦虑的时代，小院生活+为消费者倾力打造家庭、工作之外的第三生活空间，我们希望与居民做朋友，在小院生活的第三空间内提供便捷的服务、营造舒适的氛围，探寻生活的本意，引导人们在快节奏的都市中享受一份闲适与悠然。
                </div>
              </div>
            </div>
            <div class="item-wrap">
              <img src="../../assets/images/work/school.jpg" alt="">
              <div class="bottom-wrap">
                <div class="img-wrap">
                  <img src="../../assets/images/work/school.png" alt="">
                </div>
                <div class="title">咫尺一公里 教育医疗有保障</div>
                <div class="desc">
                  点亮家门口的最后一公里，小院生活+逐步引进家庭医疗、国际化教育配套，服务配套全面升级，看病不排队、读书不跑远，家门口就能解决看病难、读书难等民生问题。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap wrap3">
        <div class="page">
          <div class="title">
            商业平台
          </div>
          <div class="content-wrap">
            <div class="item-wrap">
              <div class="left">
                <img src="../../assets/images/work/B2B.jpg" alt="">
              </div>
              <div class="right">
                <div class="title">搭建B2B，B2C平台，开启消费新时代</div>
                <div class="desc">
                  小院生活+是以小院生活品牌为基础，提供与消费者居住体验相关的全周期配套服务，力求于满足消费者与居住体验相关的一站式服务。同时，依托于小院绿色生态体系与科技创新力量，与小院合作商共同为用户提供更为智慧的商业服务模式。
                </div>
              </div>
            </div>

            <div class="item-wrap">
              <div class="left">
                <img src="../../assets/images/work/require.jpg" alt="">
              </div>
              <div class="right">
                <div class="title">构建资源池，满足用户多级需求</div>
                <div class="desc">
                  小院生活与有共同理想的品牌建立深度的商务合作关系，构建资源池，通过多方的资源互补，为用户带前所未有的优惠组合体验，以及更好的资源利用率，更极致的消费体验。
                </div>
              </div>
            </div>
            <div class="item-wrap">
              <div class="left">
                <img src="../../assets/images/work/yourself.jpg" alt="">
              </div>
              <div class="right">
                <div class="title">独家订制服务 订制你的生活</div>
                <div class="desc">
                  小院生活+推出线上订制服务，订制化户型设计及装修服务，在线商城一键下单，可自主设计自己的家、全程个性化订制，告别千篇一律的装修，让每个家都能成为独一无二。
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'

export default {
  data () {
    return {

    }
  },
  components: {
    Header2022,
    Footer2022,
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding-top: 64px;
  .banner > img {
    width: 100%;
  }
  .page {
    width: 1104px;
    margin: auto;
  }
  .wrap {
    > .page {
      > .title {
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        color: #303233;
        text-align: center;
      }
    }
  }

  .wrap1 {
    background-color: #fff;
    padding: 100px 0 90px;
    .page {
      .content-wrap {
        margin-top: 74px;
        display: flex;
        .left {
          width: 416px;
          img {
            width: 100%;
          }
        }
        .right {
          flex: 1;
          margin-left: 60px;
          .title {
            font-size: 39px;
            color: #303233;
            opacity: 0.32;
            span {
              display: block;
            }
          }
          .desc {
            margin-top: 25px;
            font-size: 16px;
            color: #303233;
            line-height: 32px;
            font-weight: 400;
            text-align: justify;
          }
        }
      }
    }
  }

  .wrap2 {
    background-color: #f6f6f6;
    padding: 74px 0 54px;
    .content-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 54px;
      .item-wrap {
        background-color: #fff;
        width: 351px;
        padding-bottom: 54px;
        > img {
          width: 100%;
        }
        .bottom-wrap {
          padding: 0 36px;
          .img-wrap {
            width: 100%;
            text-align: center;
            > img {
              width: 152px;
              margin: 42px auto 0;
            }
          }
          .title {
            margin-top: 32px;
            line-height: 20px;
            font-size: 20px;
            font-weight: 900;
            color: #303233;
            text-align: center;
          }
          .desc {
            margin-top: 20px;
            font-size: 16px;
            line-height: 32px;
            color: #303233;
            text-align: justify;
          }
        }
      }
    }
  }

  .wrap3 {
    background-color: #ffffff;
    padding: 100px 0 92px;
    .content-wrap {
      margin-top: 74px;
      .item-wrap {
        background-color: #F6F6F6;
        width: 100%;
        display: flex;
        margin-bottom:24px; 
        .left{
          width: 416px;
          >img{
            width: 100%;
          }
        }
        .right{
          flex: 1;
          margin-left: 68px;
          margin-right: 90px;
          .title{
            font-size: 20px;
            line-height: 20px;
            margin-top: 60px;
            font-weight: 900;
            color: #303233;
          }
          .desc{
            font-size: 16px;
            line-height: 32px;
            margin-top: 28px;
            font-weight: 400;
            color: #303233;
          }
        }

      }
    }
  }

}
</style>